import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`This guide will show you how to import GeoIP2 or GeoLite2 databases into
PostgreSQL so that they can be easily queried and manipulated on your server.`}</p>
    <p>{`Importing a CSV database consists of downloading the database, extracting it,
creating tables to hold the data, and indexing those tables for faster querying.`}</p>
    <div {...{
      "id": "toc-download-and-extract-the-database"
    }}><h2 {...{
        "id": "download-and-extract-the-database",
        "parentName": "div"
      }}>{`Download and extract the database`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Begin by making sure you have downloaded the latest version of the GeoIP2 or
GeoLite2 database that you want to import. You can
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/geoip/downloads",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`download databases through your account portal`}</a>
        {`.
CSV format databases are shipped as a single zip file. See our CSV database
documentation for details about the zip structure and contents of the archive.
For the purposes of this tutorial, we will be using the
`}
        <a {...{
          "href": "/geoip/docs/databases/city-and-country/#csv-databases",
          "parentName": "p"
        }}>{`GeoIP2 City CSV file`}</a>
        {`,
but you can get information about
`}
        <a {...{
          "href": "/geoip/docs/databases",
          "parentName": "p"
        }}>{`any of our CSV format databases`}</a>
        {` and adapt the following
instructions accordingly.`}</p>
      <div {...{
        "id": "toc-keep-your-database-updated",
        "parentName": "div"
      }}><h3 {...{
          "id": "keep-your-database-updated",
          "parentName": "div"
        }}>{`Keep your database updated`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`If you are importing databases for use in an ongoing manner, you will want to
`}
          <a {...{
            "href": "/geoip/updating-databases/#directly-downloading-databases",
            "parentName": "p"
          }}>{`automate the process of downloading and extracting the CSV files`}</a>
          {`
so that you can be sure that your database is always up to date.`}</p></div>
      <div {...{
        "id": "toc-extract-the-csv-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "extract-the-csv-files",
          "parentName": "div"
        }}>{`Extract the CSV files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Once you have downloaded the database, unpack the zip file in your desired
directory. If you are importing the GeoIP2 City database, you will have a number
of files. In this tutorial we will be working with the following files:`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`GeoIP2-City-Blocks-IPv4.csv`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`GeoIP2-City-Blocks-IPv6.csv`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`GeoIP2-City-Locations-en.csv`}</inlineCode></li>

        </ul>
        <p {...{
          "parentName": "div"
        }}>{`If you are working in a language other than English, you can choose the
appropriate `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Locations`}</inlineCode>
          {` file from the zip archive. For example, if you want to
load Chinese location names into PostgreSQL, you can use
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-zh-CN.csv`}</inlineCode>
          {` instead of `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-en.csv`}</inlineCode>
          {`. Our
documentation on CSV format databases includes a
`}
          <a {...{
            "href": "/geoip/docs/databases/city-and-country/#locations-files",
            "parentName": "p"
          }}>{`list of all location files currently included in GeoIP2 and GeoLite2 databases`}</a>
          {`.`}</p></div></div>
    <div {...{
      "id": "toc-create-a-table-for-network-data"
    }}><h2 {...{
        "id": "create-a-table-for-network-data",
        "parentName": "div"
      }}>{`Create a table for network data`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`First we create a table to hold the network information contained in
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`GeoIP2-City-Blocks-IPv4.csv`}</inlineCode>
        {` and `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`GeoIP2-City-Blocks-IPv6.csv`}</inlineCode>
        {`.`}</p>
      <div {...{
        "id": "toc-network-table-schema",
        "parentName": "div"
      }}><h3 {...{
          "id": "network-table-schema",
          "parentName": "div"
        }}>{`Network table schema`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`You can find the full description of the schema for these files in
`}
          <a {...{
            "href": "/geoip/docs/databases/city-and-country/#blocks-files",
            "parentName": "p"
          }}>{`the section on `}
            <inlineCode {...{
              "parentName": "a"
            }}>{`Blocks`}</inlineCode>
            {` files for GeoIP2 and GeoLite2 CSV databases`}</a>
          {`.
If you are working with a different database, you can find the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Blocks`}</inlineCode>
          {` file
schema `}
          <a {...{
            "href": "/geoip/docs/databases",
            "parentName": "p"
          }}>{`for the appropriate database`}</a>
          {`, and adapt the
table to meet that structure.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`Please note that we may add additional columns to the CSV version of our GeoIP
databases at any time. Ideally, you will design any automated integration to
check the columns of the CSV and build your table schema based on the actual
columns, or remove columns from the CSV that you don't need before importing.`}</p>
          <p>{`The specific method for comparing the columns in the CSV file against the table
schema will depend on the language you're working with and how you wish to
handle additional data.`}</p>
        </Alert>
        <p {...{
          "parentName": "div"
        }}>{`In this tutorial we will be creating a table to hold our network data called
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_network`}</inlineCode>
          {`. Note that we will be using PostgreSQL's
`}
          <a {...{
            "href": "https://www.postgresql.org/docs/current/datatype-net-types.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}><inlineCode {...{
              "parentName": "a"
            }}>{`cidr`}</inlineCode></a>
          {` type
for the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`network`}</inlineCode>
          {` column. This will allow us to to query the database more
easily.`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`create table geoip2_network (
  network cidr not null,
  geoname_id int,
  registered_country_geoname_id int,
  represented_country_geoname_id int,
  is_anonymous_proxy bool,
  is_satellite_provider bool,
  postal_code text,
  latitude numeric,
  longitude numeric,
  accuracy_radius int,
  is_anycast bool
);
`}</code></pre></div>
      <div {...{
        "id": "toc-load-data-into-the-network-table",
        "parentName": "div"
      }}><h3 {...{
          "id": "load-data-into-the-network-table",
          "parentName": "div"
        }}>{`Load data into the network table`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We can now import the contents of `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Blocks-IPv4.csv`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Blocks-IPv6.csv`}</inlineCode>
          {` into the table we just created:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`\\copy geoip2_network from 'GeoIP2-City-Blocks-IPv4.csv' with (format csv, header);
`}</code></pre>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`\\copy geoip2_network from 'GeoIP2-City-Blocks-IPv6.csv' with (format csv, header);
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`If there are errors such as `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`missing data for column`}</inlineCode>
          {` or
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`extra data after last expected column`}</inlineCode>
          {`, alter the table to meet the CSV file
structure.`}</p></div>
      <div {...{
        "id": "toc-test-our-table-by-querying-it",
        "parentName": "div"
      }}><h3 {...{
          "id": "test-our-table-by-querying-it",
          "parentName": "div"
        }}>{`Test our table by querying it`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Remember that the table we created uses PostgreSQL's
`}
          <a {...{
            "href": "https://www.postgresql.org/docs/current/datatype-net-types.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`cidr`}</a>
          {` type for
the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`network`}</inlineCode>
          {` column. This built-in type represents IPv4 and IPv6 networks and
provides
`}
          <a {...{
            "href": "https://www.postgresql.org/docs/current/functions-net.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`operations`}</a>
          {` to
(among other things) determine if a given host address is contained in a
network. For example:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`select '192.168.0.0/24'::cidr >>= '192.168.0.1'; -- true
select '192.168.0.0/24'::cidr >>= '192.168.1.1'; -- false
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`We can use that to look up the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_network`}</inlineCode>
          {` entry for a given IP address
like so:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`select * from geoip2_network where network >>= '214.0.0.0';
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Which gives a result of:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "parentName": "pre"
          }}>{`-[ RECORD 1 ]------------------+-------------
network                        | 214.0.0.0/16
geoname_id                     | 6252001
registered_country_geoname_id  | 6252001
represented_country_geoname_id |
is_anonymous_proxy             | f
is_satellite_provider          | f
postal_code                    |
latitude                       | 37.7510
longitude                      | -97.8220
accuracy_radius                | 1000
is_anycast                     | f

Time: 227.970 ms
`}</code></pre></div>
      <div {...{
        "id": "toc-index-our-table-for-faster-searching",
        "parentName": "div"
      }}><h3 {...{
          "id": "index-our-table-for-faster-searching",
          "parentName": "div"
        }}>{`Index our table for faster searching`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We can now look up an individual IP address's `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`latitude`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`longitude`}</inlineCode>
          {`, and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`accuracy_radius`}</inlineCode>
          {`. To improve our query speed, we'll create an index using
PostgreSQL's
`}
          <a {...{
            "href": "https://www.postgresql.org/docs/current/textsearch-indexes.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GiST`}</a>
          {` indexing
method. For historical reasons, we also have to specify the
`}
          <a {...{
            "href": "https://www.postgresql.org/docs/9.5/gist-builtin-opclasses.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`inet_ops`}</a>
          {`
operator class:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`create index on geoip2_network using gist (network inet_ops);
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`With that change we observe that the same query runs a lot faster:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`select * from geoip2_network net where network >>= '214.0.0.0';
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Which gives a result of:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "parentName": "pre"
          }}>{`-[ RECORD 1 ]------------------+-------------
network                        | 214.0.0.0/16
geoname_id                     | 6252001
registered_country_geoname_id  | 6252001
represented_country_geoname_id |
is_anonymous_proxy             | f
is_satellite_provider          | f
postal_code                    |
latitude                       | 37.7510
longitude                      | -97.8220
accuracy_radius                | 1000
is_anycast                     | f

Time: 9.869 ms
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`You can now geolocate IP addresses using `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`latitude`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`longitude`}</inlineCode>
          {`, and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`accuracy_radius`}</inlineCode>
          {`. For more information about how to understand the
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`accuracy_radius`}</inlineCode>
          {` field, see the
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4407630607131-Geolocation-Accuracy",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`article on geolocation accuracy our knowledge base`}</a>
          {`.`}</p></div></div>
    <div {...{
      "id": "toc-optional-create-a-table-for-locations-data"
    }}><h2 {...{
        "id": "optional-create-a-table-for-locations-data",
        "parentName": "div"
      }}>{`Optional: Create a table for locations data`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`postal_code`}</inlineCode>
        {`, `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`latitude`}</inlineCode>
        {`, `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`longitude`}</inlineCode>
        {`, and `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`accuracy_radius`}</inlineCode>
        {` are everything
we're interested in we'd be done at this point and our application would be
easily able to query what it needs. However, GeoIP2 databases provide additional
location information. Note the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`geoname_id`}</inlineCode>
        {` field in our network table. This
field can be used to look up additional information about the geolocation from
the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Locations`}</inlineCode>
        {` files we downloaded before. We'll load this data into PostgreSQL
next.`}</p>
      <div {...{
        "id": "toc-locations-table-schema",
        "parentName": "div"
      }}><h3 {...{
          "id": "locations-table-schema",
          "parentName": "div"
        }}>{`Locations table schema`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We start by creating a table as before. As with the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Blocks`}</inlineCode>
          {` files, the schema
for GeoIP2 and GeoLite2 City locations files can be found in the
`}
          <a {...{
            "href": "/geoip/docs/databases/city-and-country/#locations-files",
            "parentName": "p"
          }}>{`CSV section of the database documentation`}</a>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`We'll name this table `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_location`}</inlineCode>
          {`:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`create table geoip2_location (
  geoname_id int not null,
  locale_code text not null,
  continent_code text,
  continent_name text,
  country_iso_code text,
  country_name text,
  subdivision_1_iso_code text,
  subdivision_1_name text,
  subdivision_2_iso_code text,
  subdivision_2_name text,
  city_name text,
  metro_code int,
  time_zone text,
  is_in_european_union bool not null,
  primary key (geoname_id, locale_code)
);
`}</code></pre></div>
      <div {...{
        "id": "toc-load-data-into-the-locations-table",
        "parentName": "div"
      }}><h3 {...{
          "id": "load-data-into-the-locations-table",
          "parentName": "div"
        }}>{`Load data into the locations table`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We then populate our `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_location`}</inlineCode>
          {` table from a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Locations`}</inlineCode>
          {` CSV file. In
this case we will be populating the table from `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-en.csv`}</inlineCode>
          {`.
Using the file with the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`-en`}</inlineCode>
          {` suffix will give us the names of geolocations in
English:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`\\copy geoip2_location from 'GeoIP2-City-Locations-en.csv' with (format csv, header);
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Note that there's a number of different `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Locations`}</inlineCode>
          {` files available. The other
files with different language suffixes contain localized versions of the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`-en`}</inlineCode>
          {`
data in different languages for some of the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {`s. Depending on your
application's needs you may decide to import additional `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Locations`}</inlineCode>
          {` files into
localized tables. For example, you could load `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-en.csv`}</inlineCode>
          {`
into a table called `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_location-en`}</inlineCode>
          {`, and load
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-zh-CN.csv`}</inlineCode>
          {` into a table called `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_location-zh`}</inlineCode>
          {`. You
could then query whichever locations table you needed for English or Chinese
respectively.`}</p></div>
      <div {...{
        "id": "toc-query-our-tables",
        "parentName": "div"
      }}><h3 {...{
          "id": "query-our-tables",
          "parentName": "div"
        }}>{`Query our tables`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We can now use our `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_location`}</inlineCode>
          {` table to resolve the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {`s provided
by our `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_network`}</inlineCode>
          {` table. For example:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`select latitude, longitude, accuracy_radius, continent_name, country_name, subdivision_1_name, city_name
from geoip2_network net
left join geoip2_location location on (
  net.geoname_id = location.geoname_id
  and location.locale_code = 'en'
)
where network >>= '214.0.0.0';
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Which gives a result of:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "parentName": "pre"
          }}>{`-[ RECORD 1 ]------+--------------
latitude           | 37.7510
longitude          | -97.8220
accuracy_radius    | 1000
continent_name     | North America
country_name       | United States
subdivision_1_name |
city_name          |
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Here we were only interested in English results, but we can adjust our join
condition if we were interested in different or additional languages.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Note how a left outer join is used. This is because additional location
information might not be available for any given row of our `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip2_network`}</inlineCode>
          {`
table. For example, some IP addresses cannot be resolved to a city or
subdivision. With the left join we'd still receive `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`latitude`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`longitude`}</inlineCode>
          {`, and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`accuracy_radius`}</inlineCode>
          {` as a result of our query if available, while an inner join
would result in zero rows if no additional location information was available.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`In addition to the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {` column that provides location information for a
network, there's also `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`registered_country_geoname_id`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`represented_country_geoname_id`}</inlineCode>
          {`, which provide location information about the
country in which the ISP has registered the network, and the country which is
represented by users of the IP address, respectively. The location data for both
can be included by additional joins:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-sql",
            "parentName": "pre"
          }}>{`select latitude, longitude, accuracy_radius,
       location.continent_name as location_continent_name,
       location.country_name as location_country_name,
       location.subdivision_1_name as location_subdivision_1_name,
       location.city_name as location_city_name,
       registered_country.continent_name as registered_country_continent_name,
       registered_country.country_name as registered_country_country_name,
       represented_country.continent_name as represented_country_continent_name,
       represented_country.country_name as represented_country_country_name
from geoip2_network net
left join geoip2_location location on (
  net.geoname_id = location.geoname_id
  and location.locale_code = 'en'
)
left join geoip2_location registered_country on (
  net.registered_country_geoname_id = registered_country.geoname_id
  and registered_country.locale_code = 'en'
)
left join geoip2_location represented_country on (
  net.represented_country_geoname_id = represented_country.geoname_id
  and represented_country.locale_code = 'en'
)
where network >>= '214.0.0.0';
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Which gives a result of:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "parentName": "pre"
          }}>{`-[ RECORD 1 ]----------------------+--------------
latitude                           | 37.7510
longitude                          | -97.8220
accuracy_radius                    | 1000
location_continent_name            | North America
location_country_name              | United States
location_subdivision_1_name        |
location_city_name                 |
registered_country_continent_name  | North America
registered_country_country_name    | United States
represented_country_continent_name |
represented_country_country_name   |
`}</code></pre></div></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      